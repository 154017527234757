import * as React from "react";
import { graphql, StaticQuery } from "gatsby";

import { default as ValuesCard } from 'components/ColorCard';

import DataProvider from "providers/data";

const ValuesGrid = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query ValuesGrid {
          allProjectsValue(
            sort: {
                fields: sort, 
                order: ASC
            }
            filter: {
              archived: { eq: false }
              approved: { eq: true }
              deleted: { eq: false }
            }
          ) {
            edges {
                node {
                  title
                  hashtag
                  icon
                  points
                }
            }
          }
        }
      `}
      render={(data) => {
        const { edges } = data.allProjectsValue;
        let values = edges.map(({ node }) => node);
        if ((props.providerResponse.data.length > 0 || !!values !== false) && props.providerStatusText === "LOADED") {
          values = props.providerResponse.data;
        }

        return (
          <section id="customer-values">
            <div className="grid-container">
                <div className="grid-x grid-padding-x">
                  <div className="cell flx">
                    {!!values.length && values.map((value, index) => {
                        const { title, hashtag, icon, points } = value;
                        return(
                            <div key={`value-${index}`} className="color-card-holder">
                                <ValuesCard {...{ title, hashtag, icon, points, index }} />
                            </div>
                        )
                    })}
                  </div>
                </div>
            </div>
          </section>
        );
      }}
    />
  );
};

const getData = (props) => (
  <DataProvider liveRefresh={true} type="ProjectsValue">
    <ValuesGrid {...props} />
  </DataProvider>
);
export default getData;
