import * as React from "react";
import Slider from "react-slick";

import { graphql, StaticQuery } from "gatsby";

import leftArrow from "images/slider/left-arrow.svg";
import rightArrow from "images/slider/right-arrow.svg";

import DataProvider from "providers/data";

const BrandSlider = (props) => {
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button className={`${className} brand-slider-button`} onClick={onClick}>
        <img src={rightArrow} alt="Next" />
      </button>
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
        <button className={`${className} brand-slider-button`} onClick={onClick}>
        <img src={leftArrow} alt="Previous" />
      </button>
    );
  }

  const settings = {
    autoplay: true,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const mobileSettings = {
    autoplay: true,
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <StaticQuery
      query={graphql`
        query BrandSlider {
          allProjectsBrand(
            sort: { fields: sort, order: ASC }
            filter: {
              archived: { eq: false }
              approved: { eq: true }
              deleted: { eq: false }
            }
          ) {
            edges {
              node {
                title
                logo
              }
            }
          }
        }
      `}
      render={(data) => {
        const { edges } = data.allProjectsBrand;
        let brands = edges.map(({ node }) => node);
        if (
          (props.providerResponse.data.length > 0 || !!brands !== false) &&
          props.providerStatusText === "LOADED"
        ) {
          brands = props.providerResponse.data;
        }

        return (
          <section id="brands">
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <div className="cell small-12">
                  <div className="show-for-small-only">
                    <Slider {...mobileSettings}>
                      {brands?.length &&
                        brands.map((brand, index) => (
                          <img key={`brand-${index}`} src={brand.logo} alt={brand.title} />
                        ))}
                    </Slider>
                  </div>
                  <div className="hide-for-small-only">
                    <Slider {...settings}>
                      {brands?.length &&
                        brands.map((brand, index) => (
                          <img key={`brand-${index}`} src={brand.logo} alt={brand.title} />
                        ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      }}
    />
  );
};

const getData = (props) => (
  <DataProvider liveRefresh={true} type="ProjectsBrand">
    <BrandSlider {...props} />
  </DataProvider>
);
export default getData;
