import * as React from "react"
import { graphql } from 'gatsby'

import Layout from 'templates/Layout';
import Content from 'templates/Content';
import { default as CustomerCard } from 'components/GridCard';

import ValuesGrid from 'components/customers/ValuesGrid';
import BrandSlider from 'components/customers/BrandSlider';

import DataProvider from 'providers/data';

const CustomerListing = props => {
	const { edges } = props.data.allContentPage;
	const nodes = edges.map(({ node }) => node);
	const { edges: customerEdges } = props.data.allProjectsCustomer;
	let customers = customerEdges.map(({ node }) => node);
	let page = nodes[0];
	if ((props.providerResponse.data.length > 0 || !!customers !== false) && props.providerStatusText === 'LOADED') {
		customers = props.providerResponse.data;
	}
	if (page) page.gridSize = 12;

	return (
		<Layout showContactForm={page?.showContactForm} showNewsletterSignUp={page?.showNewsletterSignUp} hoverboards={page?.hoverboards}>
			<Content gridSize={page ? 12 : 8} {...page} />
			<ValuesGrid />
			{!!customers?.length && customers.map((card, index) => (
				<CustomerCard {...card} columnSize={6} key={`customer-${index}`} order={index + 1} />
			))}
			<BrandSlider />
		</Layout>
	)
}

const getData = (props) => <DataProvider liveRefresh={props.params.liveRefresh} type="ProjectsCustomer" apiParams={{ _sort: 'sort' }}><CustomerListing {...props} /></DataProvider>
export default getData;

export const query = graphql`
{
	allContentPage(
	  filter: {uri: {eq: "/our-customers/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	) {
	  edges {
		node {
		  pageTitle
		  meta
		  content {
			main
		  }
		  showContactForm
		  showNewsletterSignUp
		  solutionBody
		  solutionLink
		  solutionLinkText
		  solutionTitle
		  solutionImage
		  solutions
		  headlineBody
		  headlineTitle
		  headlineImage
		  headlineType
		  hoverboards
		}
	  }
	}
	allProjectsCustomer(sort: {order: ASC, fields: sort}) {
	  edges {
		node {
		  icon
		  title
		  description
		  link
		  linkText
		  image
		}
	  }
	}
  }
`